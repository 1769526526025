import React, { useEffect } from 'react'
import { createContext } from 'react'
import { useAuth } from "../contexts/auth"
import { useScreenSize } from "../utils/media-query"
import notify from 'devextreme/ui/notify';
export const StoreContext = createContext(null);


export default ({ children }) => {
    const [notification, set_notification] = React.useState(null);
    const [menu_items, set_menu_items] = React.useState([]);
    const { user } = useAuth();
    const { isLarge } = useScreenSize();
    useEffect(() => {
        set_menu_items(get_menu_items());
    }, [user]);
    useEffect(() => {
        if (notification) {
            notify(notification.text, notification.type, 3000);
        }
    }, [notification]);

    const get_menu_items = () => {
        if (user && user.menus) {
            let items = [];
            items = user.menus.map((item) => {
                if (item.path && !(/^\//.test(item.path))) {
                    item.path = `/${item.path}`;
                }
                return { ...item, expanded: isLarge }
            })
            return [
                // {
                //     name: 'Trang chủ',
                //     path: '/home',
                //     icon: 'fas fa-home'
                // },
                ...items
            ];
        } else {
            return [];
        }
    }

    const can_accessed = (api) => {
        const temp = user.apis.filter(x => x.url.toUpperCase() === api.toUpperCase() || x.url.toUpperCase() === ("api/" + api).toUpperCase());
        return temp.length > 0;
    }

    const store = {
        notification: notification,
        set_notification: set_notification,
        menu_items: menu_items,
        set_menu_items: set_menu_items,
        can_accessed: can_accessed
    };

    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};